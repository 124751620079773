@font-face {
  font-family: Gilmer;
  font-size: 14px;
  src: local('Gilmer'), url(/static/media/Gilmer_Regular.f779e3a4.otf) format('opentype');
  font-display: swap;
}
@font-face {
  font-family: Gilmer-Bold;
  font-size: 14px;
  src: local('Gilmer-Bold'), url(/static/media/Gilmer_Bold.fc515556.otf) format('opentype');
  font-display: swap;
}
@font-face {
  font-family: Gilmer-Light;
  font-size: 14px;
  src: local('Gilmer-Light'), url(/static/media/Gilmer_Light.47186cc9.otf) format('opentype');
  font-display: swap;
}
@font-face {
  font-family: Gotham;
  font-size: 14px;
  src: local('Gotham'), url(/static/media/Gotham_Medium.ba7ee4ff.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: Gotham-Bold;
  font-size: 14px;
  src: local('Gotham-Bold'), url(/static/media/Gotham_Bold.b51ec430.woff) format('woff');
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Gilmer', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-ladda-primary {
  border-radius: 8px;
  border: 0;
  background-color: #00BF91 !important;
  width: 100%;
  height: 40px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: Gilmer-Light !important;
  font-size: 12px !important;
  margin-bottom: 10px;
  outline: none;
  padding: 0px 25px 0px 25px;
  transition: background-color .5s;
}

.btn-ladda-primary:disabled {
  background-color: #00a881 !important;
  cursor: not-allowed;
}

.btn-ladda-primary:hover {
  background-color: #00a881 !important;
}

.btn-small {
  width: 180px;
}

.pretty-link {
  color: #828282;
  text-decoration: underline;
}

.pretty-link:hover {
  text-decoration: none;
}

/*
  Slick overrides
*/
.slick-list {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.lazy-load-image-loaded {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipInX;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.flipOutX {
  animation-duration: 0.75s;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

.tracking-in-contract-bck-top {
	animation: tracking-in-contract-bck-top 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
@keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

.tracking-in-contract-bck-bottom {
	animation: tracking-in-contract-bck-bottom 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
@keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

.scale-down-ver-top {
	animation: scale-down-ver-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes scale-down-ver-top {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
  }
}

.scale-up-ver-top {
	animation: scale-up-ver-top 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes scale-up-ver-top {
  0% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
}

.jello-horizontal {
	animation: jello-horizontal 0.9s both;
}
@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}


.slick-slider, .slick-slider * {
  height: 100%;
}
.link {
  text-decoration: none;
  color: #ccc;
}
.link.active {
  color: #fff;
  font-weight: 700;
}
.btn-ladda-primary {
    margin: 0;
}

h1 {
  font-family: 'Gotham-Bold';
  font-size: 1.6em;
  line-height: 1.2em;
}

h4 {
  font-size: .9em;
  color: #ccc;
  line-height: 2em;
}
.developed-by {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  color: #707070;
  text-align: left;
}
.developed-by {
  font-size: 10px;
  line-height: 10px;
  font-weight: bold;
  color: #707070;
  text-align: left;
}
.pretty-select option {
  width: 99%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pretty-select option:hover {
  cursor: pointer;
  background-color: #ccc;
}

.pretty-select-open {
  transform: rotate(180deg);
  transition: transform 1s;
}

.pretty-select-close {
  transform: rotate(0deg);
  transition: transform 1s;
}

.pretty-select::-webkit-scrollbar {
  width: 10px;
}

.pretty-select::-webkit-scrollbar-track {
  background: transparent;
  border-bottom-right-radius: 5px;
  margin-bottom: 1px;
}

.pretty-select::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

.pretty-select::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

/*.pretty-select-container {
  position: relative;
}

.pretty-select-container select {
  display: none;
}

.pretty-select-selected {
  background-color: #fcfcfc;
}

.pretty-select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #A8A8A8 transparent transparent transparent;
}

.pretty-select-selected.pretty-select-arrow-active:after {
  border-color: transparent transparent #A8A8A8 transparent;
  top: 7px;
}

.pretty-select-items div, .pretty-select-selected {
  color: #A8A8A8;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

.pretty-select-items {
  position: absolute;
  background-color: #fcfcfc;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}*/

.marker-a {
  fill:#707070;
}
.marker-b {
  fill:none;
  stroke:#707070;
  stroke-width:5px;
}
.primary-text {
  color: #858585;
}

.secondary-text {
  color: #b8b8b8;
  font-size: 14px;
  line-height: 18px;
}

.observation {
  padding-top: 10px;
  border-top: 1px solid #b8b8b8;
}

.schedule .primary-text:last-child,
.schedule .secondary-text:last-child {
  padding-bottom: 8px;
}

.exception {
  font-size: 14px;
}

.regions {
  max-height: 400px;
}

.regions .exception:first-child {
  border-top: 1px solid #b8b8b8;
}
.pulsating-circle {
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
  margin: auto;
  margin-top: 15px;
}
.pulsating-circle:before {
  content: '';
  position: relative;
  display: block;
  width: 200%;
  height: 200%;
  box-sizing: border-box;
  margin-left: -50%;
  margin-top: -50%;
  border-radius: 45px;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.pulsating-circle:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}
.pulsating-circle-green:before {
  background-color: #00ffc3;
}
.pulsating-circle-green:after {
  background-color: #00bf91;
}
.pulsating-circle-red:before {
  background-color: #e67e22;
}
.pulsating-circle-red:after {
  background-color: #d35400;
}

.pulsating-mobile {
  width: 15px;
  height: 15px;
}

.pulsating-mobile:before {
  background-color: transparent;
  animation: none;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.1);
  }
  80%, 100% {
    opacity: 0;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
.products-cart-container {
  width: 88%;
  margin-top: 16px;
}

.breadcrumbs {
  color: #A8A8A8;
  padding: 8px;
}

.product-name {
  color: #707070;
  margin: 0;
}

.product-presentation {
  color: #a8a8aa;
  margin: 0;
}

.product-price {
  height: 50px;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product-row {
  border-bottom: 1px solid #A8A8A8;
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.cart-grid::-webkit-scrollbar {
  width: 10px;
}

.cart-grid::-webkit-scrollbar-track {
  background: transparent;
  border-bottom-right-radius: 5px;
  margin-bottom: 1px;
}

.cart-grid::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

.cart-grid::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  animation-delay: 1.25s, 1.25s, 1.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}
@keyframes bang {
  to {
    box-shadow: -7px 9.3333333333px #f7ff00, -74px -243.6666666667px #00ff5e, 231px -244.6666666667px #ff6600, 66px -45.6666666667px #ff00ee, -57px -22.6666666667px #0066ff, 220px -43.6666666667px #00ff33, -35px -170.6666666667px #ff5e00, -37px -145.6666666667px #ff0073, -26px 62.3333333333px #f700ff, 223px -146.6666666667px #eeff00, 79px -153.6666666667px #e6ff00, -111px -162.6666666667px #00ff09, 44px -123.6666666667px #aa00ff, 47px -123.6666666667px #00ff4d, 203px 30.3333333333px #48ff00, -15px 47.3333333333px #0009ff, -85px -354.6666666667px #2200ff, 156px -313.6666666667px #dd00ff, -12px -68.6666666667px #ff8800, -9px -222.6666666667px #00ff9d, 159px -372.6666666667px #d9ff00, 37px -312.6666666667px #d5ff00, 59px 2.3333333333px #f7ff00, 213px -260.6666666667px yellow, -52px -178.6666666667px #d9ff00, 224px -299.6666666667px #00ff7b, 160px -180.6666666667px #ff009d, 234px -84.6666666667px #00ffb3, -124px 25.3333333333px #0051ff, 159px -108.6666666667px #ff0004, 48px 18.3333333333px #3c00ff, -48px 61.3333333333px #ff0051, 162px -90.6666666667px #00e1ff, -60px -113.6666666667px #ffbf00, 79px -311.6666666667px #ff00cc, -234px -77.6666666667px #4400ff, 78px -20.6666666667px #22ff00, 36px -191.6666666667px #3c00ff, 83px -318.6666666667px #008cff, -92px 21.3333333333px #8000ff, 59px -24.6666666667px #00b7ff, 237px -126.6666666667px #ffd900, -218px -256.6666666667px darkorange, 21px -390.6666666667px #a200ff, 156px -309.6666666667px #ff0011, -101px -408.6666666667px #001aff, 42px 54.3333333333px #ff0900, 139px 73.3333333333px #6200ff, -181px -40.6666666667px #c400ff, -34px 60.3333333333px #0011ff, 190px -295.6666666667px #bf00ff;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

.filter-dropdown-open {
  transform: rotate(-90deg);
  transition: transform 1s;
}

.filter-dropdown-close {
  transform: rotate(0deg);
  transition: transform 1s;
}
.filter-dropdown::-webkit-scrollbar {
  width: 10px;
}

.filter-dropdown::-webkit-scrollbar-track {
  background: transparent;
  border-bottom-right-radius: 5px;
  margin-bottom: 1px;
}

.filter-dropdown::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

.filter-dropdown::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
.BrainhubCarousel__arrows {
  background-color: #00BF91;
  border-radius: 8px;
}

.BrainhubCarousel__arrows:hover {
  background-color: #00BF91;
}
.product-modal {
  width: 80%;
  border-radius: 10px;
}
li {
  margin-bottom: 5px;
  color: #707070;
}
.content {
  font-family: 'Gilmer';
}

h2 {
  color      : #757575;
  font-weight: 900;
}

span {
  font-size: 13px;
  margin   : 0;
  padding  : 0;
}

strong {
  font-size    : 15px;
  margin-bottom: 10px;
  color        : #686868;
}

ul {
  list-style-type: none;
  padding        : 0;
  margin         : 10px 0 0 0;
}

.radio-button {
  margin: 0 0 0.25em 0;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]+label {
  color      : #292321;
  font-family: Arial, sans-serif;
  font-size  : 14px;
}

input[type="radio"]+label span {
  display           : inline-block;
  width             : 13px;
  height            : 13px;
  margin            : -1px 4px 0 0;
  vertical-align    : middle;
  cursor            : pointer;
  border-radius     : 50%;
}

input[type="radio"]+label span {
  background-color: transparent;
  border          : 2px solid #999;
}

input[type="radio"]:checked+label span {
  background-color: #00bf91;
  border          : 2px solid transparent;
}

input[type="radio"]+label span,
input[type="radio"]:checked+label span {
  transition        : background-color 0.4s linear;
}

.default-input {
  border       : 1px solid #999;
  border-radius: 7px;
  font-size    : 13px;
  padding      : 5px 0 5px 5px;
  outline      : none;
}

.react-autosuggest__input {
  width        : 100%;
  height       : 40px;
  border       : 1px solid #707070;
  color        : #707070;
  font-family  : Gilmer;
  font-size    : 16px;
  cursor       : pointer;
  border-radius: 10px;
  outline      : none;
  text-align   : center;
  padding      : 0px;
}

.react-autosuggest__input--focused {
  border-color: #00BF91;
}

.react-autosuggest__input:disabled{
  background-color: #c5c1c1;
}

.react-autosuggest__suggestions-container--open {
  position        : absolute;
  display         : flex;
  z-index         : 99;
  width           : 99.6%;
  border          : 1px solid #00BF91;
  border-radius   : 0px 0px 10px 10px;
  border-top      : none;
  align-items     : center;
  background-color: white;
  justify-content : center;
}

.react-autosuggest__container--open {
  position: relative;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0px;
}

.react-autosuggest__suggestion {
  cursor: pointer;
}

.react-autosuggest__suggestions-list {
  overflow       : auto;
  width          : 100%;
  align-items    : center;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  max-height     : 200px;
}

.highlighted{
  color : #00BF91
}

.empty{
  border-color: red;
}

.input-container{
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
}

.icon{
  position: absolute;
  right: 10px;
}
.trash-alt-a {
  fill:#505050;
}
.address-card-disappear {
  height: 0px;
  transition: height 1s;
}
.address-card-disappear {
  height: 0px;
  transition: height 1s;
}
.address-card-disappear {
  height: 0px;
  transition: height 1s;
}
.pills-a {
  fill: #a8a8a8;
}
.BrainhubCarousel__arrows {
  background-color: #00BF91;
  border-radius: 8px;
}

.BrainhubCarousel__arrows:hover {
  background-color: #00BF91;
}
.primary-text {
  color: #858585;
}

.secondary-text {
  color: #b8b8b8;
}

.observation {
  padding-top: 10px;
  border-top: 1px solid #b8b8b8;
}

.schedule .primary-text:last-child,
.schedule .secondary-text:last-child {
  padding-bottom: 8px;
}

.exception {
  font-size: 14px;
}

.regions .exception:first-child {
  border-top: 1px solid #b8b8b8;
}
.products-cart-container {
  width: 88%;
  margin-top: 16px;
}

.breadcrumbs {
  color: #A8A8A8;
  padding: 8px;
}

.product-name {
  color: #707070;
  margin: 0;
}

.product-presentation {
  color: #a8a8aa;
  margin: 0;
}

.product-price {
  height: 50px;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.address-card-disappear {
  height: 0px;
  transition: height 1s;
}
.address-card-disappear {
  height: 0px;
  transition: height 1s;
}
.address-card-disappear {
  height: 0px;
  transition: height 1s;
}
.content {
  font-family: 'Gilmer';
}

h2 {
  color: #757575;
  font-weight: 900;
}

span {
  font-size: 13px;
  margin: 0;
  padding: 0;
}

strong {
  font-size: 15px;
  margin-bottom: 10px;
  color: #686868;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0 0;
}

.radio-button {
  margin: 0 0 0.25em 0;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  color: #292321;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

input[type="radio"] + label span {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
}

input[type="radio"] + label span {
  background-color: transparent;
  border: 2px solid #999;
}

input[type="radio"]:checked + label span {
  background-color: #00bf91;
  border: 2px solid transparent;
}

input[type="radio"] + label span,
input[type="radio"]:checked + label span {
  transition: background-color 0.4s linear;
}

.default-input {
  border: 1px solid #999;
  border-radius: 7px;
  font-size: 13px;
  padding: 5px 0 5px 5px;
  outline: none;
}

